import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { PageHeaderV2, SEO } from "src/components";

import Main from "./_components/_main";
import Offline from "./_components/_offline";

const DonatePage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        getInvolved: prismicGetInvolved {
          dataString
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.getInvolved.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={"Donate"}
            description={
              page.donate_description &&
              RichText.asText(page.donate_description)
            }
          />
          <PageHeaderV2
            heading={page.donate_header && RichText.asText(page.donate_header)}
            image={page.donation_image}
            color="brand.altPrimary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          <Main
            description={
              page.donate_description &&
              RichText.render(page.donate_description, linkResolver, serializer)
            }
            buttonText={page.donate_button_text}
            buttonLink={page.donate_button_link}
          />
          <Offline
            header={
              page.offline_donation_header &&
              RichText.asText(page.offline_donation_header)
            }
            email={page.offline_donation_email}
            phone={page.offline_donation_phone}
          />
        </>
      );
    }}
  />
);

export default DonatePage;
