import React from "react";

import { Box, FlexCol, SectionWrapper, H2, H6, Text } from "src/components";

const Offline = ({ header, phone, email }) => (
  <Box bg="bg.wash">
    <SectionWrapper>
      <Box py={[7, 7, 9]}>
        <FlexCol width={1}>
          <Box>
            {header && <H2 children={header} fluid mb={3} />}
            <Box my={5}>
              <H6 children={`Phone`} />
              {phone && (
                <Text
                  children={phone}
                  fluid
                  mt={2}
                  fontSize={2}
                  css={`
                    font-variant-numeric: tabular-nums;
                  `}
                />
              )}
            </Box>
            <Box my={5}>
              <H6 children={`Email`} />
              {phone && (
                <Text
                  children={email}
                  fluid
                  mt={2}
                  fontSize={2}
                  css={`
                    font-variant-numeric: tabular-nums;
                  `}
                />
              )}
            </Box>
          </Box>
        </FlexCol>
      </Box>
    </SectionWrapper>
  </Box>
);

export default Offline;
